/* global window:true */
/* eslint no-underscore-dangle: 0 */

import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

export default devtools => {
  let store
  if (devtools) {
    store = createStore(rootReducer, compose(applyMiddleware(...middlewares), devtools))
  } else {
    store = createStore(rootReducer, compose(applyMiddleware(...middlewares)))
  }

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
};
