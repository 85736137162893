const actions = {
    SET_HOME_DATA: 'SET_HOME_DATA',

    ON_LOAD: 'HOME_ON_LOAD',
    onLoad: payload => ({
        type: actions.ON_LOAD,
        payload
    }),

    SET_PHONE_TO_TEMP: 'SET_PHONE_TO_TEMP',
    ON_PHONE_TO_TEMP: 'ON_PHONE_TO_TEMP',
    onPhoneSaveToTemp: payload => ({
        type: actions.ON_PHONE_TO_TEMP,
        payload
    }),

    ON_PHONE_SUBMIT: 'HOME_ON_PHONE_SUBMIT',
    onPhoneSubmit: (payload,url) => ({
        type: actions.ON_PHONE_SUBMIT,
        payload,
        url
    }),

};

export default actions
