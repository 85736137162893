import React from 'react'
import { Provider } from 'react-redux'

import createStore from './src/state/store'

const devtools =
  process.env.NODE_ENV === 'development' && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f

const store = createStore(devtools)

if (window) {
  window.inventreeStore = store
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => <Provider store={store}>{element}</Provider>
