import axios from 'axios'
// import {getLanguage} from '../locale'

// const lang = getLanguage().lang;
const baseURL = process.env.GATSBY_BASE_URL_API
const END_POINT = baseURL + process.env.GATSBY_API_PATH

// function checkToken() {
//     const accessToken = getAccessToken().get('accessToken') || getFacebookToken();
//     const idToken = getIdToken().get('idToken') || getFacebookIDToken();
//     return {
//         accessToken,
//         idToken,
//     }
// }

function httpRequest(method, url, data) {
  // let addOnHeader = {};
  //
  // const token = checkToken();
  //
  // if (token.accessToken) {
  //     addOnHeader = {
  //         ...addOnHeader,
  //         Authorization: token.accessToken,
  //     }
  // }
  //
  // if (token.idToken) {
  //     addOnHeader = {
  //         ...addOnHeader,
  //         'x-id-token': token.idToken,
  //     }
  // }

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  return axios({
    method,
    url,
    headers,
    data,
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

// const instanceForm = axios.create({
//     baseURL,
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//     },
// });

export const delay = ms => new Promise(resolve => setTimeout(() => resolve(true), ms))

// Get Banners
export async function getBanner() {
  try {
    const data = await httpRequest('GET', `${END_POINT}/banners`, null)
    data.data.banners = data.data.banners || []
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Add Record
export async function apiAddRecord(payload) {
  if (payload.phone_number === '0') {
    payload.phone_number = ''
  }
  try {
    const data = await httpRequest('POST', `${END_POINT}/record`, payload)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Sync Profile with AWS Cognito
export async function syncProfileCognito() {
  try {
    const data = await httpRequest('PUT', `${END_POINT}/profile`, null)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}
