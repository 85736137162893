import actions from './actions'

const initState = {
    slider_show: [],
    phone_temp: {
        staff_id: "",
        phone_number: "",
        app_id: ''
    }
};

export default function homeReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_HOME_DATA:
            return {
                ...state,
                slider_show: action.slider_show
            };
        case actions.SET_PHONE_TO_TEMP:
            return {
                ...state,
                phone_temp: action.payload
            };
        default:
            return state
    }
}
