import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { navigate } from 'gatsby-link'
import swal from 'sweetalert'

import actions from './actions'
import {
  delay,
  getBanner,
  apiAddRecord,
} from '../../utils/api'

export function* onLoad() {
  yield takeEvery(actions.ON_LOAD, function* (action) {
    const bannerData = yield call(getBanner)
    yield call(delay, 300)
    yield put({
      type: actions.SET_HOME_DATA,
      slider_show: bannerData.data.banners,
    })
    yield action.payload.callback()
  })
}

export function* onPhoneSaveToTemp() {
  yield takeEvery(actions.ON_PHONE_TO_TEMP, function* (action) {
    if (action.payload.phone_number === '' || action.payload.staff_id === '') {
      yield swal('พบข้อผิดพลาด', 'กรุณากรอกข้อมูลให้ครบถ้วน', 'error')
    } 
    else if (action.payload.staff_id.length !== 7 && action.payload.staff_id.length !== 8) {
      yield swal('พบข้อผิดพลาด', 'รหัสพนักงานต้องเป็นเลข 7 หรือ 8 ตัว', 'error')
    } 
    else if (action.payload.phone_number.length !== 10) {
      yield swal('พบข้อผิดพลาด', 'เบอร์โทรต้องเป็นเลข 10 ตัว', 'error')
    } else {
      yield put({
        type: actions.SET_PHONE_TO_TEMP,
        payload: action.payload,
      })
  
      yield navigate(`/menu/?id=${action.payload.staff_id}&phone=${action.payload.phone_number}`)
    }
  })
}

export function* onPhoneSubmit() {
  yield takeEvery(actions.ON_PHONE_SUBMIT, function* (action) {

    if (action.payload.phone_number === '') {
      swal('พบข้อผิดพลาด', 'กรุณากรอกข้อมูลให้ครบถ้วน', 'error')
    }else if (action.payload.staff_id.length !== 7 && action.payload.staff_id.length !== 8) {
      yield swal('พบข้อผิดพลาด', 'รหัสพนักงานต้องเป็นเลข 7 หรือ 8 ตัว', 'error')
    } 
    else if (action.payload.phone_number.length !== 10) {
      yield swal('พบข้อผิดพลาด', 'เบอร์โทรต้องเป็นเลข 10 ตัว', 'error')
    } 

    const res = yield call(apiAddRecord, action.payload)

    if (res.code === 'OK') {
      window.location = action.url
      // window.open(action.url, '_blank');
    } else {
      swal('พบข้อผิดพลาด', res.text.error, 'error')
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(onLoad),
    fork(onPhoneSaveToTemp),
    fork(onPhoneSubmit),
  ])
}
